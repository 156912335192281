
import { defineComponent, reactive, ref, toRefs, watch, nextTick, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute, onBeforeRouteLeave } from 'vue-router';
import { ColumnProps } from 'ant-design-vue/es/table/interface';
import { Modal, message } from 'ant-design-vue';
import Pagination from '@/components/Pagination.vue';
import ProgramPreview from '@/views/Approve/components/ProgramPreview.vue';
import TableWrapper from '@/components/TableWrapper.vue';
import useTableRowHighlight from '@/hooks/approve/useTableRowHighlight';
import useFetch from '@/hooks/useFetch';
import useTableHeight from '@/hooks/useTableHeight';
import ShowCommentsModel from '@/views/Approve/components/ShowCommentsModel.vue';
import WordingBudget from '@/views/Program/Wording/WordingBudget.vue';
import {
  getProgramHomePageData,
  getBrand,
  submitProgram,
  getSubscribedBu,
  getVehicleTypeClass,
  getProgramStatus,
  deleteProgram,
  copyProgram,
  createPackage,
  createReleaseBatch,
  cancelProgram,
  witchDrawProgram,
  callbackProgram,
  getHistoryComments,
  reviseProgram,
  confirmProgram,
  getProgramCategory,
  getProgramCategoryFleet,
  isWordingUploadAllBatch
} from '@/API/approve';
import { QueryParams, Program, BuItem, TypeClassItem, ProgramStatusItem, BrandItem, CommentProps } from './types';
import moment from 'moment';
import blobDownload from '@/utils/blobDownload';

type Key = ColumnProps['key'];

const columns = [
  {
    title: 'Program Code',
    dataIndex: 'programCode',
    key: 'programCode',
    width: 170,
    slots: { customRender: 'programCode' },
    fixed: 'left',
    align: 'left',
    ellipsis: true
  },
  {
    title: 'Program Name',
    dataIndex: 'programName',
    key: 'programName',
    slots: { customRender: 'programName' },
    width: 220,
    fixed: 'left',
    align: 'left',
    ellipsis: true
  },
  {
    title: 'Program Period From',
    dataIndex: 'programPeriodFrom',
    key: 'programPeriodFrom',
    width: 160,
    align: 'center'
  },
  {
    title: 'To',
    dataIndex: 'programPeriodTo',
    key: 'to',
    width: 160,
    align: 'center'
  },
  {
    title: 'Batch Approve Name',
    dataIndex: 'batchApproveName',
    key: 'batchApproveName',
    slots: { customRender: 'batchApproveName' },
    width: 200,
    align: 'left'
  },
  {
    title: 'Batch Release Name',
    dataIndex: 'batchReleaseName',
    key: 'batchReleaseName',
    slots: { customRender: 'batchReleaseName' },
    width: 200,
    align: 'left'
  },
  {
    title: 'Program Status',
    dataIndex: 'programStatus',
    key: 'programStatus',
    slots: { customRender: 'batchprogramStatus' },
    width: 120,
    align: 'center'
  },
  {
    title: 'Sales Status',
    dataIndex: 'salesStatus',
    key: 'salesStatus',
    width: 130,
    align: 'center'
  },
  {
    title: 'Parameter Status',
    dataIndex: 'parameterStatus',
    key: 'parameterStatus',
    width: 130,
    align: 'center'
  },
  {
    title: 'Controlling Status',
    dataIndex: 'controllingStatus',
    key: 'controllingStatus',
    width: 130,
    align: 'center'
  },
  {
    title: 'Header Program Code',
    dataIndex: 'programLeadCode',
    key: 'programLeadCode',
    width: 200,
    align: 'center'
  }
];
const parameterStatusOptions = ['Draft', 'Confirmed'];
export default defineComponent({
  name: 'ProgramHomePage',
  components: {
    Pagination,
    ProgramPreview,
    TableWrapper,
    ShowCommentsModel,
    WordingBudget
  },
  beforeRouteEnter(to, from, next) {
    const fromList = ['Design', 'createPackage', 'releaseSetting', 'Budgut Input', 'Parameter Set Up'];
    next((vm: any) => {
      const lastParams = vm.$store.state.programHomeParams;
      if (from.name !== null && from.name !== undefined && fromList.includes(from.name as string)) {
        if (lastParams !== null) {
          vm.brandResetFlag = false;
          Object.assign(vm.queryParams, lastParams);
        }
      } else {
        vm.$store.commit('removeProgramHomeParams');
      }
    });
  },
  setup() {
    const { commit } = useStore();
    const { tableHeight } = useTableHeight();

    const isOpen = ref(false);
    const handleCollapse = () => {
      isOpen.value = !isOpen.value;
      nextTick(() => {
        const tablePosition = useTableHeight(true, '.pagination-container', true);
        tableHeight.value = tablePosition.tableHeight.value;
      });
    };

    const visible = ref<boolean>(false);

    const programPreviewData = reactive<{
      programId: string;
      programPeriod: string;
    }>({
      programId: '',
      programPeriod: ''
    });

    const router = useRouter();

    const route = useRoute();

    const callBackVisible = ref<boolean>(false);

    const callbackText = ref<string>('');
    const commentsObj = reactive<{
      callBackTextVisible: boolean;
      comments: CommentProps[];
    }>({
      callBackTextVisible: false,
      comments: []
    });

    const flag = ref<number>(0);

    //brand options
    const brandOptions = ref<BrandItem[]>([]);

    //query Params
    const queryParams = reactive<QueryParams>({
      bu: [],
      name: '',
      brand: [],
      typeClass: [],
      periodFrom: moment()
        .quarter(moment().quarter() - 1)
        .startOf('quarter')
        .format('YYYY-MM-DD'),
      periodTo: moment()
        .month(moment().month() + 1)
        .endOf('month')
        .format('YYYY-MM-DD'),
      status: '',
      mine: false,
      programCategory: '',
      code: '',
      parameterStatus: ''
    });
    //table data
    const { data, pagination, refreshFetch } = useFetch<Program[]>(queryParams, [], getProgramHomePageData, true);

    //查询type class options
    const { data: typeClassOptions } = useFetch<TypeClassItem[]>({}, [], getVehicleTypeClass);

    //查询bu options
    const { data: buOptions } = useFetch<BuItem[]>({}, [], getSubscribedBu);
    //查询program status
    const { data: programStatusOptions } = useFetch<ProgramStatusItem[]>({}, [], getProgramStatus);
    const programCategoryOptions = ref();

    onMounted(async () => {
      const programCategoryData = await getProgramCategory();
      const programCategoryDataFleet = await getProgramCategoryFleet();
      programCategoryOptions.value = programCategoryData.concat(programCategoryDataFleet);
    });

    const { currentClickRowIndex, handlerRowClick } = useTableRowHighlight(visible);

    const brandResetFlag = ref(true);
    const fetchFindBrandByBu = async (buCode: string[]): Promise<void> => {
      try {
        //重置brand 和  选择的brand
        brandOptions.value = [];
        if (brandResetFlag.value) queryParams.brand = [];
        const result = await getBrand({ params: { bu: buCode } });
        const brands = result.map((item) => {
          return item.brands;
        });
        Object.assign(brandOptions.value, brands.flat());
      } catch (e) {
        console.log(e);
      }
    };

    const rowSelection = reactive<{
      selectedRowKeys: Key[];
      selectedRowData: Program[];
      onChange: Function;
    }>({
      onChange: (selectedRowKeys: Key[], selectedRows: Program[]) => {
        rowSelection.selectedRowKeys = selectedRowKeys;
        rowSelection.selectedRowData = selectedRows;
      },
      selectedRowKeys: [],
      selectedRowData: []
    });

    const filterOption = (input: string, option: any) => {
      return option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };

    const validateProgramStatus = (status: string, messageText: string): Promise<any> => {
      return new Promise<void>((resolve, reject) => {
        if (rowSelection.selectedRowData.length == 0) {
          message.error('Please select programs');
          return reject();
        }
        if (!rowSelection.selectedRowData.every((item) => item.programStatus == status)) {
          message.error(messageText);
          return reject();
        }
        return resolve();
      });
    };

    const resetTableSelectData = (): void => {
      rowSelection.selectedRowKeys = [];
      rowSelection.selectedRowData = [];
    };

    const handlerResetClick = (): void => {
      Object.assign(queryParams, {
        bu: [],
        name: '',
        brand: [],
        typeClass: [],
        periodFrom: moment()
          .quarter(moment().quarter() - 1)
          .startOf('quarter')
          .format('YYYY-MM-DD'),
        periodTo: moment()
          .month(moment().month() + 1)
          .endOf('month')
          .format('YYYY-MM-DD'),
        status: '',
        mine: false,
        programCategory: '',
        code: '',
        parameterStatus: ''
      });
      refreshFetch(1);
      resetTableSelectData();
    };
    const _copyProgram = (copyEditedContent: boolean) => {
      copyProgram({
        programId: rowSelection.selectedRowData[0].programId,
        copyEditedContent
      }).then((data) => {
        router.push({
          path: '/programdesign/design',
          query: { pgmId: data.programId }
        });
      });
    };
    const handlerCopyClick = (): void => {
      if (rowSelection.selectedRowData.length === 0) {
        message.error('Please select!');
        return;
      }
      if (rowSelection.selectedRowData.length > 1) {
        message.error('请选择一条需要copy的program');
        return;
      }
      Modal.confirm({
        title: 'Note',
        content: 'Do you want copy program along with edited wording content?',
        onOk() {
          _copyProgram(true);
        },
        cancelText: 'No',
        onCancel() {
          _copyProgram(false);
        }
      });
    };

    const handlerSubmitClick = (): void => {
      if (rowSelection.selectedRowData.length === 0) {
        message.error('Please select!');
        return;
      }
      Modal.confirm({
        title: '提示',
        content: 'Submit for Controlling Approve ？',
        onOk() {
          const data = rowSelection.selectedRowData.map((item) => ({
            id: item.programId
          }));
          // return new Promise<void>((resolve, reject) => {
          //     submitProgram({ program: data })
          //         .then((res) => {
          //             refreshFetch(1);
          //             resetTableSelectData();
          //             resolve();
          //         })
          //         .catch((e) => {
          //             reject();
          //         });
          // });
          isWordingUploadAllBatch({ program: data })
            .then((res) => {
              if (!res) {
                message.error('Submit failed. Please upload updated wording for internal/external wording');
                return Promise.reject();
              }
            })
            .then(() => {
              return submitProgram({ program: data });
            })
            .then(() => {
              message.success('Submit successfully!');
              refreshFetch(1);
              resetTableSelectData();
            })
            .catch((e) => {
              console.log(e);
            });
        },
        onCancel() {
          console.log('Cancel');
        }
      });
    };

    const handlerCreatePackageClick = (): void => {
      if (rowSelection.selectedRowData.length === 0) {
        message.error('Please select!');
        return;
      }
      const body = {
        batchName: '',
        bu: '',
        programs: rowSelection.selectedRowData.map((item) => ({
          programId: item.programId
        }))
      };
      createPackage(body).then((data) => {
        commit('approve/updateProgramIds', body.programs);
        router.push('/approval/sales/createPackage');
      });
    };

    const handlerReleaseSetClick = (): void => {
      if (rowSelection.selectedRowData.length === 0) {
        message.error('Please select!');
        return;
      }
      const programs = rowSelection.selectedRowData.map((item) => ({
        programId: item.programId
      }));
      createReleaseBatch({ programs }).then((data) => {
        commit('approve/updateProgramIds', programs);
        router.push('/approval/sales/releaseSetting');
      });
    };

    const handlerRevisionClick = (): void => {
      if (rowSelection.selectedRowData.length === 0) {
        message.error('Please select!');
        return;
      }
      callBackVisible.value = true;
      flag.value = 3;
    };

    const handlerDeleteClick = (): void => {
      if (rowSelection.selectedRowData.length === 0) {
        message.error('Please select!');
        return;
      }
      Modal.confirm({
        title: '提示',
        content: 'Delete Program ?',
        onOk() {
          new Promise<void>((resolve, reject) => {
            const program = rowSelection.selectedRowData.map((item) => ({
              id: item.programId
            }));
            deleteProgram({ program: program })
              .then((data) => {
                //重置分页为第一页，查询
                pagination.page = 1;
                refreshFetch();
                resetTableSelectData();
                resolve();
              })
              .catch((e) => {
                reject();
              });
          });
        },
        onCancel() {
          console.log('Cancel');
        }
      });
    };

    const handlerCancelClick = (): void => {
      if (rowSelection.selectedRowData.length === 0) {
        message.error('Please select!');
        return;
      }
      callBackVisible.value = true;
      flag.value = 1;
    };

    const handlerWithdrawClick = (): void => {
      if (rowSelection.selectedRowData.length === 0) {
        message.error('Please select!');
        return;
      }
      callBackVisible.value = true;
      flag.value = 2;
    };

    // confirm
    const handlerConfirm = () => {
      if (rowSelection.selectedRowData.length === 0) {
        message.error('Please select!');
        return;
      }
      const programId = rowSelection.selectedRowData.map((item) => item.programId);
      const params = {
        programId
      };
      confirmProgram(params).then(() => {
        refreshFetch(1);
        resetTableSelectData();
        message.success('Confirmed successfully');
      });
    };

    // export
    const handlerExport = () => {
      if (rowSelection.selectedRowData.length === 0) {
        const param = Object.assign({}, queryParams, {
          programIds: [],
          parameterStatus: queryParams.parameterStatus !== '' ? [queryParams.parameterStatus] : [],
          programCategory: queryParams.programCategory !== '' ? [queryParams.programCategory] : [],
          status: queryParams.status !== '' ? [queryParams.status] : []
        });
        //console.log(param);
        blobDownload({
          url: 'claimapi/parameter/setup/export-new',
          method: 'post',
          params: param
        });
        return;
      }
      const programIds = rowSelection.selectedRowData.map((item) => item.programId);
      blobDownload({
        url: 'claimapi/parameter/setup/export-new',
        method: 'post',
        params: { programIds }
      });
    };

    //分页事件处理
    const handlerPaginationChange = (page: number): void => {
      pagination.page = page;
      refreshFetch();
      resetTableSelectData();
    };

    const handlerShowSizeChange = (current: number, pageSize: number): void => {
      pagination.pageSize = pageSize;
      Object.assign(pagination, { page: current, pageSize: pageSize });
      refreshFetch();
      resetTableSelectData();
    };

    // woridng preview
    const visiblePreview = ref(false);
    const customRow = (record: Program, index: number): { onClick: Function } => {
      const _visible = visible;
      return {
        onClick: () => {
          // _visible.value = true;
          programPreviewData.programId = record.programId;
          programPreviewData.programPeriod = `${moment(record.programPeriodFrom).format('YYYY.MM')}-${moment(
            record.programPeriodTo
          ).format('YYYY.MM')}`;

          sessionStorage.setItem('previewPgmId', record.programId);
          visiblePreview.value = true;

          handlerRowClick(index);
        }
      };
    };

    const handlerSearchClick = (): void => {
      resetTableSelectData();
      refreshFetch(1);
    };

    const handlerPeriodChange = (date: string[]): void => {
      queryParams.periodFrom = date[0];
      queryParams.periodTo = date[1];
    };

    const hideModal = () => {
      callBackVisible.value = false;

      const program = rowSelection.selectedRowData.map((item) => item.programId);
      if (flag.value === 1) {
        cancelProgram({
          programId: program,
          comment: callbackText.value
        }).then(() => {
          //重置分页为第一页，查询
          pagination.page = 1;
          refreshFetch();
          resetTableSelectData();
        });
      } else if (flag.value === 2) {
        callbackProgram({
          programId: program,
          comment: callbackText.value
        }).then(() => {
          //重置分页为第一页，查询
          pagination.page = 1;
          refreshFetch();
          resetTableSelectData();
        });
      } else if (flag.value === 3) {
        if (rowSelection.selectedRowData.length > 1) {
          message.error('Please select only one!');
          return;
        }
        const pid = rowSelection.selectedRowData[0].programId;
        reviseProgram({
          programId: pid,
          comment: callbackText.value
        }).then(() => {
          //重置分页为第一页，查询
          pagination.page = 1;
          refreshFetch();
          resetTableSelectData();
        });
      }
      flag.value = 0;
      callbackText.value = '';
    };
    const cancelModel = () => {
      callBackVisible.value = false;
      callbackText.value = '';
    };
    // 显示comments
    const handleShowComments = (programId: string, programStatus: string) => {
      getHistoryComments({
        params: { programId: programId, programStatus: programStatus }
      }).then((res) => {
        commentsObj.comments = res.comment;
        if (res.comment.length > 0) {
          commentsObj.callBackTextVisible = true;
        } else {
          message.warning('No Comments!');
        }
      });
    };
    watch(
      () => queryParams.bu,
      (value) => {
        if (value.length > 0) {
          fetchFindBrandByBu(value);
        } else {
          brandOptions.value = [];
          queryParams.brand = [];
        }
      }
    );

    onBeforeRouteLeave((to) => {
      console.log('queryParams', queryParams);
      commit('addProgramHomeParams', queryParams);
      //     const PROGRAM_HOME = 'programHome';
      //     console.log('to.name', to.name);
      //     const toList = ['Design', 'createPackage', 'releaseSetting'];
      //     if (
      //         to.name !== null &&
      //         to.name !== undefined &&
      //         toList.includes(to.name as string)
      //     ) {
      //         commit('addKeepAliveComponentName', PROGRAM_HOME);
      //     } else {
      //         commit('removeKeepAliveComponentName', PROGRAM_HOME);
      //     }
    });

    return {
      //brandOptions
      brandOptions,

      //query Params
      queryParams,
      ...toRefs(queryParams),
      brandResetFlag,

      //table
      columns,
      data,
      rowSelection,
      customRow,

      pagination,

      refreshFetch,
      handlerResetClick,

      //搜索的options
      typeClassOptions,
      programStatusOptions,
      buOptions,

      //处理按钮操作
      handlerSubmitClick,
      handlerCreatePackageClick,
      handlerReleaseSetClick,
      handlerRevisionClick,
      handlerDeleteClick,
      handlerCancelClick,
      handlerWithdrawClick,
      handlerCopyClick,
      handlerSearchClick,
      handlerPeriodChange,

      //分页的事件处理
      handlerPaginationChange,
      handlerShowSizeChange,

      //typeClass搜索配置
      filterOption,

      //program preview
      visible,
      programPreviewData,

      tableHeight,

      //高亮显示table row
      currentClickRowIndex,
      cancelModel,
      hideModal,
      callBackVisible,
      callbackText,
      handleShowComments,
      ...toRefs(commentsObj),

      isOpen,
      handleCollapse,
      visiblePreview,

      handlerConfirm,
      handlerExport,

      parameterStatusOptions,
      programCategoryOptions
    };
  }
});
